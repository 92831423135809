// this is the correct mime type we expect for these
const mimeType = "application/json";

export const allClientData = new Map();

// expose for debugging
window.allClientData = allClientData;

/**
 * Finds and parses the JSON script tag associated to the selector parameter, or returns null
 * @param {string} selector The DOMString corrosponding to the id of the <script> tag to find
 * @throws {DOMException} Upon an invalid selector
 * @throws {Error} Upon reading a script with an incorrect mime-type — should be "application/json"
 * @returns {any} The result of parsing the script tag
 */
export const getClientData = (selector) => {
    if (!selector.endsWith("-data")) {
        throw new Error(`Naming Convention: "${selector}" should end with "-data". e.g. "${selector}-data"`);
    }

    const scriptEl = document.querySelector(selector);

    // enforce us to not incorrectly attaching running-code
    if (scriptEl !== null && scriptEl.type.toLowerCase() !== mimeType) {
        throw new Error(`Script corrosponding to "${selector}" is not of type "${mimeType}"`);
    }

    if (scriptEl === null) {
        return null;
    }

    let data = null;

    try {
        data = JSON.parse(scriptEl.textContent);
    } catch (ex) {
        if (ex.message.includes("Unexpected token } in JSON at position")) {
            console.warn("getClientData: You may have a trailing comma within your script element");
            console.log(scriptEl);
        }

        if (ex.message.includes("Unexpected string in JSON at position")) {
            console.warn("getClientData: You may be missing a comma within your script element");
            console.log(scriptEl);
        }

        if (ex.message.includes("Unexpected token : in JSON at position")) {
            console.warn("getClientData: You may have forgotten to wrap your json with braces");
            console.log(scriptEl);
        }

        console.error(`Failed to parse "${selector}" — ${ex.message}`);
    }

    allClientData.set(selector, data);
    return data;
};

export const getComponentProps = (id) => getClientData(`#${id}`);
